<template>
  <SampleWizard />
</template>

<script>
import SampleWizard from "@/components/newSamples/SampleWizard";
export default {
  name: "SampleEdit",
  components: { SampleWizard }
};
</script>

<style scoped></style>
